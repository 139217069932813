export const RouterPrefix = {
  APPLE: '/apple',
  MY_PAGE: '/myPage',
} as const;
export const HomePath = {
  THEME_SELECT: '/home/themeSelect',
  THEME_DETAIL: '/home/themeDetail',
} as const;

export const MyPagePath = {
  MAIN: '/',
  NEWS: '/news',
  PROFILE: '/myProfile',
  USER_SEARCH: '/userSearch',
  FOOD_DETAIL: '/ketogram/feed/:ketogram_idx',

  MY_FEED: '/myFeed',

  MAIN_ROOM: '/mainRoom',
  ROOM_SELECT: '/roomSelect',
  ROOM_EDITOR: '/roomEditor',
  MAGAZINE: '/roomSelect/magazine',

  SETTING: '/setting',
  ACCOUNT: '/setting/accountDisclosure',
  ACCOUNT_MANAGEMENT: '/setting/accountManagement',
  ACCOUNT_FOLLOW_REQUEST: '/setting/followRequest',
  ACCOUNT_USER_BAN: '/setting/userBan',
  LOCK: '/setting/accountLock',
  ALARM: '/setting/alarm',
  ALARM_CUSTOM: '/setting/customAlarm',
  ALARM_CUSTOM_POSTING: '/setting/customAlarm/posting',
  ALARM_CUSTOM_EDIT: '/setting/customAlarm/posting/edit',
  INOUT_TEAM: '/setting/inoutTeam',
  TERMS_PRIVACY: '/setting/termsPrivacy',
  AUTH_INFO: '/setting/logoutSignout',
  MANAGE_POSTS: '/setting/managePost',
  MANAGE_SCRAPS: '/setting/manageScrap',
  MY_FOOD_REVIEW: '/setting/myFoodReview',
  COMMUNICATION: '/setting/communication',
  AD_REMOVE: '/setting/adRemove',
} as const;

export const ApplePath = {
  MAIN: '/',
  POINT: '/point',
  STORE_THEME: '/store/theme',
  STORE_MY_ROOM: '/store/myRoom',
  STORE_THEME_DETAIL: '/store/theme/detail',
  STORE_MY_ROOM_DETAIL: '/store/myRoom/detail',
  THEME_WISHLIST: '/store/wishlist',
};

export const RecipePath = {
  RECIPE_REGISTRATION: '/:mealId/:page',
  INGREDIENT_DETAIL: '/ingredient/:id',
  RECIPE_DETAIL: '/detail/:mealId/:id',
} as const;

export const NotionPath = {
  LOGIN_INQUIRY: '/loginInquiry',
  TERMS: '/terms',
  PRIVACY: '/privacy',
  UPDATE_NEWS: '/updateNews',
  FAQ: '/faq',
  EVENT: '/event',
} as const;

export const HealthPath = {
  ANDROID_HEALTH_CONNECT: '/androidHealthConnect',
  HEALTH_APP_AUTH: '/healthAppAuth',
} as const;
