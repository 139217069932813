import {
  BoardRequest,
  CommunityReport,
  Feed as FeedV2,
  FeedDetail,
  InfluencerFeed,
  InfluencerUser,
  KetogramComment,
  KetogramCommentEditRequest,
  KetogramCommentRequest,
  KetogramDetailCheck,
  KetogramDetailInfo,
  KetogramEditRequest,
  KetogramFeed,
  KetogramFeedResponse,
  KetogramFollower,
  KetogramFollowing,
  KetogramLike,
  KetogramMealDetailRequest,
  KetogramMealRequest,
  KetogramNormalRequest,
  KetogramPostingMealResponse,
  KetogramPostingRecipeResponse,
  KetogramRecipeRequest,
  KetogramReplyEditRequest,
  KetogramReplyRequest,
  PopularCommunityTypeEN,
  PopularFeedResponse,
  popularKetogramQueryParams,
  RecommendFeed,
  RecommendSection,
  RecommendUser,
  ResponseKetogramComment,
  ResponseKetogramReply,
  ResponseMsg,
  TrendingUser,
} from '@models/community';
import { RegisteredFoodList } from '@models/dietDiary';
import {
  Feed,
  MyProfileRequest,
  NavigationNews,
  News,
  NewsStatus,
  ProfileInfo,
  ScrapFeed,
  UserSearchResultResponseType,
} from '@models/myPage';
import { del, get, patch, post } from '@modules/HttpWebClient/default';

export async function postKetogramNormal(request: KetogramNormalRequest): Promise<ResponseMsg> {
  const url = '/ketogram/diary';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function postKetogramRecipe(request: KetogramRecipeRequest): Promise<ResponseMsg> {
  const url = '/ketogram/recipe';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function postingKetogramMeal(request: KetogramMealRequest): Promise<ResponseMsg> {
  const url = '/ketogram/menu';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function getKetogramFeedDetail(ketogram_idx: number): Promise<KetogramFeed> {
  const url = `/ketogram/feed/detail/${ketogram_idx}`;
  const { data } = await get<KetogramFeed>(url);
  return data;
}

export async function getFeedDetailV2(ketogram_idx: number): Promise<FeedDetail> {
  const url = `/ketogram/feed/detail/${ketogram_idx}`;
  const { data } = await get<FeedDetail>(url);
  return data;
}

export async function editKetogram(request: KetogramEditRequest): Promise<ResponseMsg> {
  const url = '/ketogram';
  const { data } = await patch<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function delKetogram(
  ketogram_idx: number,
  ketogram_type: number,
): Promise<ResponseMsg> {
  const url = `/ketogram/${ketogram_idx}/${ketogram_type}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function delKetogramV2(ketogram_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/${ketogram_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function checkKetogramDetail(ketogram_idx: number): Promise<KetogramDetailCheck> {
  const url = `/ketogram/detail/check/${ketogram_idx}`;
  const { data } = await get<KetogramDetailCheck>(url);
  return data;
}

// regCHeck => 1: 이미 공유된 ; 0: 아직 공유 안된
export async function getPostingKetogramRecipeList(
  regCheck: 1 | 0,
): Promise<KetogramPostingRecipeResponse> {
  const url = `/ketogram/recipe/${regCheck}`;
  const { data } = await get<KetogramPostingRecipeResponse>(url);
  return data;
}
// regCHeck => 1: 이미 공유된 ; 0: 아직 공유 안된
export async function getPostingKetogramMealList(
  regCheck: 1 | 0,
): Promise<KetogramPostingMealResponse> {
  const url = `/ketogram/menu/${regCheck}`;
  const { data } = await get<KetogramPostingMealResponse>(url);
  return data;
}

export async function getKetogramFeedList(ketogram_idx: number): Promise<KetogramFeed[]> {
  const url = `/ketogram/feed/${ketogram_idx}`;
  const { data } = await get<KetogramFeed[]>(url, {
    // cancelToken: cancelSource.token,
  });
  return data;
}

export async function getFeedsV2(ketogram_type: string, ketogram_idx: number): Promise<FeedV2[]> {
  let url = `/ketogram/feed/category/${ketogram_idx}/${ketogram_type}`;

  if (ketogram_type === '99') {
    url = `/ketogram/feed/${ketogram_idx}`;
  }

  const { data } = await get<FeedV2[]>(url);
  return data;
}

export const getFeedSearch = async ({
  memberIdx,
  tag,
  from,
}: {
  memberIdx: number;
  tag: string;
  from: number;
}): Promise<FeedV2[]> => {
  const url = `/v2/ketogram/search?member_idx=${memberIdx}&tag=${encodeURIComponent(
    tag,
  )}&from=${from}`;
  const { data } = await get<{ result: FeedV2[] }>(url);

  return data.result;
};

export async function getKetogramFeedList2(
  ketogram_type: number,
  ketogram_idx: number,
): Promise<KetogramFeedResponse> {
  let url = `/ketogram/feed/category/${ketogram_idx}/${ketogram_type}`;

  if (ketogram_type === 99) {
    url = `/ketogram/feed/${ketogram_idx}`;
  }

  const { data } = await get<KetogramFeed[]>(url, {
    // cancelToken: cancelSource.token,
  });
  return {
    feedList: data,
    isMore: data.length === 10,
    lastIndex: data[data.length - 1].ketogram_idx,
  };
}

export async function getPopularKetogram(
  type: PopularCommunityTypeEN,
  params: popularKetogramQueryParams,
): Promise<PopularFeedResponse> {
  const url = `/v2/ketogram/popular/${type}`;

  const { data } = await get<PopularFeedResponse>(url, { params });
  return data;
}

export async function getKetogramCategoryFeedList(
  ketogram_idx: number,
  ketogram_type: number,
): Promise<KetogramFeed[]> {
  const url = `/ketogram/feed/category/${ketogram_idx}/${ketogram_type}`;
  const { data } = await get<KetogramFeed[]>(url, {});
  return data;
}

export async function getKetogramDetailSubInfo(ketogram_idx: number): Promise<KetogramDetailInfo> {
  const url = `/ketogram/detail/sub/info/${ketogram_idx}`;
  const { data } = await get<KetogramDetailInfo>(url);
  return data;
}

export async function postKetogramLike(ketogram_idx: number): Promise<ResponseMsg> {
  const url = '/ketogram/like';
  const { data } = await post<ResponseMsg>(url, {
    ketogram_idx: ketogram_idx,
  });
  return data;
}

export async function delKetogramLike(ketogram_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/like/inout/${ketogram_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function getKetogramLikeList(ketogram_idx: number): Promise<KetogramLike[]> {
  const url = `/ketogram/like/list/${ketogram_idx}`;
  const { data } = await get<KetogramLike[]>(url);
  return data;
}

export async function getUserSearch(
  member_idx: number,
  { keyword, from }: { keyword: string; from: number },
): Promise<UserSearchResultResponseType[]> {
  const url = `/members/${member_idx}/search?name=${encodeURIComponent(
    keyword,
  )}&from=${from}&size=20`;
  const { data } = await get<UserSearchResultResponseType[]>(url);
  return data;
}

export async function postKetogramFollowing(following_member_idx: number): Promise<ResponseMsg> {
  const url = '/ketogram/sns/following';
  const { data } = await post<ResponseMsg>(url, {
    following_member_idx: following_member_idx,
  });
  return data;
}

export async function delKetogramFollowing(following_member_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/sns/following/${following_member_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function getKetogramFollowingList(member_idx: number): Promise<KetogramFollowing[]> {
  const url = `/ketogram/sns/following/list/${member_idx}`;
  const { data } = await get<KetogramFollowing[]>(url);
  return data;
}

export async function getKetogramFollowerList(member_idx: number): Promise<KetogramFollower[]> {
  const url = `/ketogram/sns/follower/list/${member_idx}`;
  const { data } = await get<KetogramFollower[]>(url);
  return data;
}

export async function postKetogramScrap(ketogram_idx: number): Promise<ResponseMsg> {
  const url = '/ketogram/scrap';
  const { data } = await post<ResponseMsg>(url, {
    ketogram_idx,
  });
  return data;
}

export async function delKetogramScrap(ketogram_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/scrap/inout/${ketogram_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function postKetogramComment(
  request: KetogramCommentRequest,
  timezone: string,
): Promise<ResponseKetogramComment> {
  const url = `/ketogram/comment?timezone=${timezone}`;
  const { data } = await post<ResponseKetogramComment>(url, {
    ...request,
  });
  return data;
}

export async function getKetogramCommentList(
  ketogram_idx: number,
  type: number,
): Promise<KetogramComment[]> {
  const url = `/ketogram/comment/${ketogram_idx}/${type}`; // type은 임시로 달아논 것입니다.
  const { data } = await get<KetogramComment[]>(url);
  return data;
}

export async function getKetogramCommentListV2(
  ketogram_idx: number,
  type: number,
): Promise<KetogramComment[]> {
  const url = `/ketogram/comment/v2/${ketogram_idx}/${type}`; // type은 임시로 달아논 것입니다.
  const { data } = await get<KetogramComment[]>(url);
  return data;
}

export async function editKetogramComment(
  request: KetogramCommentEditRequest,
): Promise<ResponseMsg> {
  const url = '/ketogram/comment';
  const { data } = await patch<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function delKetogramComment(ketogram_comment_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/comment/inout/${ketogram_comment_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function postKetogramReply(
  request: KetogramReplyRequest,
  timezone: string,
): Promise<ResponseKetogramReply> {
  const url = `/ketogram/reply?timezone=${timezone}`;
  const { data } = await post<ResponseKetogramReply>(url, {
    ...request,
  });
  return data;
}

export async function editKetogramReply(request: KetogramReplyEditRequest): Promise<ResponseMsg> {
  const url = '/ketogram/reply';
  const { data } = await patch<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function delKetogramReply(ketogram_reply_idx: number): Promise<ResponseMsg> {
  const url = `/ketogram/reply/inout/${ketogram_reply_idx}`;
  const { data } = await del<ResponseMsg>(url);
  return data;
}

export async function postKetogramCommentLike(ketogram_comment_idx: number) {
  const url = `/ketogram/comment/like/${ketogram_comment_idx}`;
  await post(url);
}

export async function postKetogramReplyLike(ketogram_reply_idx: number) {
  const url = `/ketogram/reply/like/${ketogram_reply_idx}`;
  await post(url);
}

export async function reportCs(request: CommunityReport): Promise<ResponseMsg> {
  const url = '/cs/report';
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function reportFeed(params: {
  ketogram_type: '피드' | '댓글' | '대댓글';
  report_idx: number;
}): Promise<ResponseMsg> {
  const url = `/ketogram/report?ketogram_type=${params.ketogram_type}&ketogram_type_idx=${params.report_idx}`;
  const { data } = await post<ResponseMsg>(url);
  return data;
}

export async function getKetogramRegisterdList({
  ketogram_idx,
  time,
  created_at,
}: KetogramMealDetailRequest): Promise<RegisteredFoodList> {
  const url = `/food/reg/ketogram/${time}/${created_at}/${ketogram_idx}`;
  const { data } = await get<RegisteredFoodList>(url);
  return data;
}

// 마이페이지
export async function getProfileFeedList(member_idx: number, ketogramIdx: number) {
  const url = `/profile/feed/${member_idx}/${ketogramIdx}`;
  const { data } = await get<Feed[]>(url);

  return data;
}

export async function getMyPageScrapList(
  ketogram_scrap_idx: number,
): Promise<{ myPageScrabs: ScrapFeed[]; isMore: boolean; lastIndex: number }> {
  const url = `/profile/feed/scrap/list/${ketogram_scrap_idx}`;
  const { data } = await get<ScrapFeed[]>(url);
  return {
    myPageScrabs: data,
    isMore: data.length === 10,
    lastIndex: data[data.length - 1].ketogram_scrap_idx,
  };
}

export async function getProfileInfo(memberIdx: number, memberIdxTo: number): Promise<ProfileInfo> {
  const url = `/v2/members/${memberIdx}/profile/${memberIdxTo}`;
  const { data } = await get<ProfileInfo>(url);
  return data;
}

export async function postMyProfile(
  memberIdx: number,
  request: MyProfileRequest,
): Promise<ResponseMsg> {
  const url = `/v2/members/${memberIdx}/profile`;
  const { data } = await post<ResponseMsg>(url, {
    ...request,
  });
  return data;
}

export async function getNewsStatus(): Promise<NewsStatus> {
  const url = '/news/tab/check';
  const { data } = await get<NewsStatus>(url);
  return data;
}

export async function getNewsList(news_type: number, news_idx: number): Promise<News[]> {
  const url = `/news/list/${news_type}/${news_idx}`;
  const { data } = await get<News[]>(url);
  return data;
}

export async function getNavigationNews(): Promise<NavigationNews> {
  const url = '/news/navigation/check';
  const { data } = await get<NavigationNews>(url);
  return data;
}

export async function getCheckBookmark(memberIdx: number, boardIdx: number) {
  const url = `/members/${memberIdx}/bookmark/boards/${boardIdx}`;
  const { data } = await get<boolean>(url);

  return data;
}

export async function postBoardScrap({ memberIdx, boardIdx }: BoardRequest) {
  const url = `/members/${memberIdx}/bookmark/boards`;
  const { status } = await post<unknown>(url, {
    board_idx: boardIdx,
  });

  return status === 200;
}

export async function delBoardScrap({ memberIdx, boardIdx }: BoardRequest) {
  const url = `/members/${memberIdx}/bookmark/boards/${boardIdx}`;
  const { status } = await del<unknown>(url);

  return status === 200;
}

export const getRecommendSection = async (): Promise<RecommendSection[]> => {
  const url = `/v2/ketogram/pick/feed`;
  const { data } = await get<{ results: RecommendSection[] }>(url);
  return data.results;
};

export const getTrendingUser = async (member_idx: number): Promise<TrendingUser[]> => {
  const url = `/v2/ketogram/pick/trendingUser?member_idx=${member_idx}`;
  const { data } = await get<{ results: TrendingUser[] }>(url);
  return data.results;
};

export const getInfluencer = async (member_idx: number): Promise<InfluencerUser[]> => {
  const url = `/v2/ketogram/pick/influencer?member_idx=${member_idx}`;
  const { data } = await get<{ results: InfluencerUser[] }>(url);
  return data.results;
};

export const getInfluencerFeed = async (
  member_idx: number,
  target_member_idx: number,
): Promise<InfluencerFeed> => {
  const url = `/v2/ketogram/pick/influencer/detail?member_idx=${member_idx}&target_member_idx=${target_member_idx}`;
  const { data } = await get<{ result: InfluencerFeed[] }>(url);
  return data?.result?.[0];
};

export const getRecommend = async ({
  member_idx,
  title,
  isCustom,
}: {
  member_idx: number;
  title: string;
  isCustom: boolean;
}): Promise<RecommendFeed[] | RecommendUser[]> => {
  const url = `/v2/ketogram/pick/feed/section?member_idx=${member_idx}&title=${encodeURIComponent(
    title,
  )}&custom=${isCustom}`;
  const { data } = await get<{ result: RecommendFeed[] | RecommendUser[] }>(url);
  return data.result;
};
